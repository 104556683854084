.mde-header {
  flex-shrink: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  border-bottom: 1px solid #c8ccd0;
  border-radius: 2px 2px 0 0;
  background: #f9f9f9; }
  .mde-header .mde-tabs {
    display: flex;
    flex-direction: row; }
    .mde-header .mde-tabs button {
      border-radius: 2px;
      margin: 6px 3px;
      background-color: transparent;
      border: 1px solid transparent;
      cursor: pointer; }
      .mde-header .mde-tabs button:first-child {
        margin-left: 6px; }
      .mde-header .mde-tabs button.selected {
        border: 1px solid #c8ccd0; }
  .mde-header .svg-icon {
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: inherit;
    overflow: visible;
    vertical-align: -.125em; }
  .mde-header ul.mde-header-group {
    margin: 0;
    padding: 10px;
    list-style: none;
    display: flex;
    flex-wrap: nowrap; }
    .mde-header ul.mde-header-group.hidden {
      visibility: hidden; }
    .mde-header ul.mde-header-group li.mde-header-item {
      display: inline-block;
      position: relative;
      margin: 0 4px; }
      .mde-header ul.mde-header-group li.mde-header-item button {
        text-align: left;
        cursor: pointer;
        height: 22px;
        padding: 4px;
        margin: 0;
        border: none;
        background: none;
        color: #242729; }

@keyframes tooltip-appear {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
        .mde-header ul.mde-header-group li.mde-header-item button.tooltipped:hover::before {
          animation-name: tooltip-appear;
          animation-duration: 0.2s;
          animation-delay: 0.5s;
          animation-fill-mode: forwards;
          opacity: 0;
          position: absolute;
          z-index: 1000001;
          width: 0;
          height: 0;
          color: rgba(0, 0, 0, 0.8);
          pointer-events: none;
          content: "";
          border: 5px solid transparent;
          top: -5px;
          right: 50%;
          bottom: auto;
          margin-right: -5px;
          border-top-color: rgba(0, 0, 0, 0.8); }
        .mde-header ul.mde-header-group li.mde-header-item button.tooltipped:hover::after {
          animation-name: tooltip-appear;
          animation-duration: 0.2s;
          animation-delay: 0.5s;
          animation-fill-mode: forwards;
          font-size: 11px;
          opacity: 0;
          position: absolute;
          z-index: 1000000;
          padding: 5px 8px;
          color: #fff;
          pointer-events: none;
          content: attr(aria-label);
          background: rgba(0, 0, 0, 0.8);
          border-radius: 3px;
          right: 50%;
          bottom: 100%;
          transform: translateX(50%);
          margin-bottom: 5px;
          white-space: nowrap; }
      .mde-header ul.mde-header-group li.mde-header-item ul.react-mde-dropdown {
        position: absolute;
        left: 0;
        top: 30px;
        background-color: white;
        border: 1px solid #c8ccd0;
        padding: 5px;
        z-index: 2;
        transform: translateX(-9px); }
        .mde-header ul.mde-header-group li.mde-header-item ul.react-mde-dropdown li {
          margin: 0;
          white-space: nowrap;
          list-style: none;
          display: block; }
          .mde-header ul.mde-header-group li.mde-header-item ul.react-mde-dropdown li button {
            display: block;
            height: auto; }
            .mde-header ul.mde-header-group li.mde-header-item ul.react-mde-dropdown li button p {
              display: block;
              margin: 0;
              padding: 0;
              font-weight: bold;
              line-height: 1em;
              background: none;
              border: 0;
              text-align: left; }
              .mde-header ul.mde-header-group li.mde-header-item ul.react-mde-dropdown li button p:hover {
                color: #4078c0; }
              .mde-header ul.mde-header-group li.mde-header-item ul.react-mde-dropdown li button p.header-1 {
                font-size: 20px; }
              .mde-header ul.mde-header-group li.mde-header-item ul.react-mde-dropdown li button p.header-2 {
                font-size: 18px; }
              .mde-header ul.mde-header-group li.mde-header-item ul.react-mde-dropdown li button p.header-3 {
                font-size: 14px; }
              .mde-header ul.mde-header-group li.mde-header-item ul.react-mde-dropdown li button p.header-4 {
                font-size: 12px; }
        .mde-header ul.mde-header-group li.mde-header-item ul.react-mde-dropdown::before {
          position: absolute;
          content: "";
          width: 0;
          height: 0;
          border: 8px solid transparent;
          border-bottom-color: rgba(0, 0, 0, 0.15);
          top: -16px;
          left: 3px;
          transform: translateX(50%); }
        .mde-header ul.mde-header-group li.mde-header-item ul.react-mde-dropdown::after {
          position: absolute;
          content: "";
          width: 0;
          height: 0;
          border: 7px solid transparent;
          border-bottom-color: white;
          top: -14px;
          left: 5px;
          transform: translateX(50%); }
